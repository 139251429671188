import { Alert, Form, Image, Modal, Typography } from "antd";
import { useExportExcelDischarge, usePreReadDischargeFile } from "../queries";

import { ButtonVariant } from "components/button/types";
import CrossIcon from "assets/icons/CrossIcon.svg";
import { CustomButtom } from "components/button/CustomButton";
import { ERROR_MESSAGE_CODE } from "constants/constants";
import ErrorAlertIcon from "assets/icons/ErrorAlertIcon.svg";
import { Upload } from "components/Upload";
import { ValidationPreRead } from "../queries/types";
import XLSXIcon from "assets/icons/XLSXIcon.svg";
import useDidMountEffect from "hooks/use-did-mount-effect";
import { useState } from "react";

export type FromType = {
  File?: {
    originFileObj: File;
  }[];
};

export interface IProps {
  modalTitle?: string;
  subTitle?: string;
  callBackSuccess: (formValue: FormData | string[]) => void;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  isSuccessConfirmation: boolean;
  isLoadingSubmit: boolean;
  callBackResponseData: (value: ValidationPreRead) => void;
  country: string;
}

export const INIT_UPLOAD_DATA = {
  File: null,
};

export default function ModalUploadDischarge({
  modalTitle,
  callBackSuccess,
  isOpen,
  setOpen,
  subTitle,
  isSuccessConfirmation,
  isLoadingSubmit,
  callBackResponseData,
  country,
}: IProps) {
  const [form] = Form.useForm();
  const [triggerResetUploadStyle, setTriggerResetUploadStyle] =
    useState<boolean>(false);
  const [totalReadFileItem, setTotalReadFileItem] = useState<number | null>(
    null
  );

  const { mutate: exportExcelDischarge, isLoading: isLoadingExportDischarge } =
    useExportExcelDischarge();

  const [errorMessages, setErrorMesses] = useState("");

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setTriggerResetUploadStyle(!triggerResetUploadStyle);
    setOpen(false);
  };
  const handleReset = () => {
    form.setFieldsValue({ File: INIT_UPLOAD_DATA.File });
  };

  useDidMountEffect(() => {
    if (isSuccessConfirmation) {
      form.resetFields();
      setTriggerResetUploadStyle(!triggerResetUploadStyle);
    }
  }, [isSuccessConfirmation]);

  const onFinish = (values: FromType) => {
    var formData = new FormData();
    if (values.File) {
      formData.append("file", values.File[0].originFileObj);
      formData.append("countryName", country);
    }
    callBackSuccess(formData);
  };

  const file = Form.useWatch("File", form);

  const { mutate: preReadFile, isLoading: isReading } =
    usePreReadDischargeFile();

  const exportExcel = () => {
    exportExcelDischarge(undefined, {
      onSuccess: (data: any) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `eDo_Discharge_template.xlsx`);
        document.body.appendChild(link);
        link.click();
      },
    });
  };

  const handleCallBackError = (value: boolean) => {
    if (!value) {
      if (file && file.length > 0) {
        var formData = new FormData();
        formData.append("file", file[0].originFileObj);
        formData.append("countryName", country);
        const payload = {
          file: formData as FormData,
        };
        preReadFile(payload, {
          onSuccess: (response) => {
            //handle empty file
            setTotalReadFileItem(response.data.numberOfItem);
            callBackResponseData(response.data);
          },
          onError: (err: any) => {
            if (err?.response?.data?.Message === ERROR_MESSAGE_CODE.MSG_04) {
              setErrorMesses("File is not in correct template");
            }
          },
        });
      }
    }
  };

  useDidMountEffect(() => {
    if (file && file.length === 0) {
      setErrorMesses("");
    }
  }, [file]);

  return (
    <Modal
      closable={false}
      className="!w-[512px]"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
      centered
    >
      <div className="custom-confirmation-modal-customer-management ">
        <div>
          <div>
            <h1 className="text-blue-sapphire text-[20px] not-italic font-antonio font-bold m-[0px] tracking-[-0.1px]">
              {modalTitle}
            </h1>
            <p className="text-blue-rock-blue p-[0px] font-roboto text-[14px] not-italic font-normal mt-[8px]">
              {subTitle}
            </p>
          </div>
          {(!file || (file && file.length === 0)) && (
            <div className="flex items-center justify-between bg-blue-solitude-light rounded-lg px-3 py-4 my-5">
              <div className="flex flex-col">
                <div className="flex justify-start items-center mb-2">
                  <img src={XLSXIcon} alt="" />
                  <Typography.Text className="text-blue-sapphire font-roboto text-sm font-normal not-italic leading-5">
                    File Example
                  </Typography.Text>
                </div>
                <Typography.Text className="text-blue-waikawa-grey font-roboto text-xs not-italic font-normal leading-[14px] w-[313px]">
                  You can download the attached example and use them as a
                  starting point for your own file.
                </Typography.Text>
              </div>
              <CustomButtom
                title="Download"
                variant={ButtonVariant.outline}
                customSize={"medium"}
                customColor={"secondary"}
                color="blue"
                htmlType="button"
                loading={isLoadingExportDischarge}
                onClick={exportExcel}
              >
                Download
              </CustomButtom>
            </div>
          )}
          <div className="mt-[20px]">
            <Form
              form={form}
              onFinish={onFinish}
              autoComplete="off"
              layout="vertical"
              requiredMark={false}
              initialValues={INIT_UPLOAD_DATA}
            >
              <div className="custom-customer-modal-customer-management">
                <Upload
                  triggerReset={triggerResetUploadStyle}
                  callBackResetFile={handleReset}
                  fileImages={[{ icon: XLSXIcon, type: "xlsx" }]}
                  fileTypes={["xlsx", "xls"]}
                  overSizeValue={2} //20MB
                  loadingReadFile={isReading}
                  callBackHasError={handleCallBackError}
                />

                {((file && file.length > 0 && totalReadFileItem === 0) ||
                  !!errorMessages) && (
                  <Alert
                    rootClassName="flex items-center"
                    showIcon
                    closable
                    icon={
                      <Image
                        preview={false}
                        src={ErrorAlertIcon}
                        alt="alert-cross-icon"
                        className="!w-fit"
                      />
                    }
                    closeIcon={
                      <Image
                        preview={false}
                        src={CrossIcon}
                        alt="alert-cross-icon"
                      />
                    }
                    message={`${
                      errorMessages
                        ? errorMessages
                        : `Uploading File is empty. Please reupload then try again!`
                    }`}
                    type="warning"
                    className=" w-full rounded py-1.5 px-3 border-none [&_.ant-alert-message]:text-blue-sapphire [&_.ant-alert-message]:font-roboto [&_.ant-alert-message]:font-normal [&_.ant-alert-message]:text-xs [&_.ant-alert-message]:leading-[0.875rem] mb-4"
                  />
                )}
                <div className="grid grid-cols-12 gap-x-4 mt-8">
                  <CustomButtom
                    title="Cancel"
                    variant={ButtonVariant.outline}
                    customSize={"medium"}
                    customColor={"secondary"}
                    customClassName="col-span-6"
                    onClick={handleCancel}
                  />
                  <CustomButtom
                    disabled={
                      totalReadFileItem === 0 || (file && !!errorMessages)
                    }
                    title="Submit"
                    customSize={"medium"}
                    customColor={"secondary"}
                    customClassName="col-span-6"
                    htmlType="submit"
                    loading={isLoadingSubmit || isReading}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
}
