import { AccountRolesAndCountries, UpdateAccountPayload } from "./interfaces";

import { IFetchApi } from "libs/fetchAPI";
import { omitEmptyParams } from "libs/helper";

export type GetAccountListParams = {
  isInternal?: boolean;
  CountryName: string;
  RoleName?: string;
  CompanyName?: string;
  PageNum?: number;
  PageSize?: number;
};

export type CountryListParams = {
  isGetAll?: boolean;
};

export const accountServices = {
  getAccountFilters: (isInternal: boolean = false, countryName: string = ''): IFetchApi => {
    const searchParams = new URLSearchParams({
      isInternal: String(isInternal),
      countryName: String(countryName)
    });

    return {
      url: `accounts/filter?${searchParams}`,
      method: "get",
      isAuth: true,
    };
  },
  getAccountList: (params?: GetAccountListParams): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
      isInternal: String(params?.isInternal), // parse to string
    });

    return {
      url: `accounts?${searchParams}`,
      method: "get",
      isAuth: true,
    };
  },
  updateAccount: (payload: UpdateAccountPayload): IFetchApi => ({
    url: `accounts/${payload.ccgid}`,
    method: "put",
    isAuth: true,
    payload: payload,
  }),
  createAccount: (payload: UpdateAccountPayload): IFetchApi => ({
    url: `accounts`,
    method: "post",
    isAuth: true,
    payload: payload,
  }),
  assignRole: (payload: {
    data: AccountRolesAndCountries[];
    ccgid: string;
  }): IFetchApi => ({
    url: `accounts/${payload.ccgid}/roles`,
    method: "put",
    isAuth: true,
    payload: payload.data,
  }),
  getAccountRolesAndCountries: (ccgid: string): IFetchApi => ({
    url: `accounts/${ccgid}/roles`,
    method: "get",
    isAuth: true,
  }),
  getCountryLis: (params?: CountryListParams): IFetchApi => {
    const searchParams = new URLSearchParams({
      ...omitEmptyParams(params),
    });
    return {
      url: `accounts/list-country?${searchParams}`,
      method: "get",
      isAuth: true,
    };
  },
};
