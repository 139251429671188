import {
  ConfigProvider,
  Form,
  Image,
  Pagination,
  Table,
  Typography,
} from "antd";
import { useMemo, useState } from "react";
import { useGetAccountFilters, useGetAccountList } from "../queries";

import AngleLeft from "assets/icons/AngleLeft.svg";
import AngleRight from "assets/icons/AngleRight.svg";
import CrossIcon from "assets/icons/CrossIcon.svg";
import SearchIcon from "assets/icons/SearchIcon.svg";
import { CustomSortIcon } from "components/CustomSortIcon";
import { CustomTooltip } from "components/CustomTooltip";
import { CustomButtom } from "components/button/CustomButton";
import { FormInput } from "components/form/Input";
import { TableHeaderTitle } from "components/table/HeaderTitle";
import { DEFAULT_PERPAGE } from "constants/constants";
import dayjs from "dayjs";
import { insertItemIntoArray } from "libs/helper";
import get from "lodash/get";
import { useCountry } from "pages/eod-management/components/SelectCountry";
import { Account } from "../interfaces";
import { CustomSelect } from "./CustomSelect";
import { EmptyTable } from "./EmptyTable";
import { ModalAccount } from "./ModalAccount";
import { ModalAssignRole } from "./ModalAssignRole";
import { useTableAccountsColumns } from "./useTableAccountsColumn";

type TabItemProps = {
  isInternal?: boolean;
  isOpenModalAddNewAccount?: boolean;
  setOpenModalAddNewAccount?: (value: boolean) => void;
};

type Filters = {
  RoleName: string;
  CountryName: string;
  CompanyName: string;
  Keyword: string;
};

const INITIAL_FILTERS: Filters = {
  RoleName: "",
  CountryName: "",
  CompanyName: "",
  Keyword: "",
};

const IniAccount = {
  id: "",
  ccgid: "",
  companyName: "",
  companyCode: "",
  countryName: "",
  email: "",
  firstName: "",
  lastName: "",
  roles: [""],
  roleName: "",
  userName: "",
  isActive: false,
  createdBy: "",
  createdDate: "",
  updatedBy: "",
  updatedDate: "",
  isExecutor: false,
};

const statusOPtions = [
  { label: "All Status", value: "" },
  { label: "Active", value: "true" },
  { label: "Inactive", value: "false" },
];

export const TabItem = ({
  isInternal = false,
  isOpenModalAddNewAccount,
  setOpenModalAddNewAccount,
}: TabItemProps) => {
  const [PageNum, setPageNum] = useState(1);
  const [searchParams, setSearchParams] = useState(INITIAL_FILTERS);
  const [form] = Form.useForm<Filters>();
  const [isOpenModalAssignRole, setOpenModalAssignRole] =
    useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<Account>(IniAccount);
  const [openModalEdit, setOpenModalEdit] = useState<boolean>(false);

  const handleActions = (event: any, record: Account) => {
    setSelectedAccount(record);
    switch (event.key) {
      case "edit":
        setOpenModalEdit(true);
        break;
      case "assign_role":
        setOpenModalAssignRole(true);
        break;
    }
  };

  const defaultTableColumns = useTableAccountsColumns({
    isInternal,
    handleActions,
  });
  // get account filters
  const currentCountry = useCountry();
  const { data: accountFilters, isLoading } = useGetAccountFilters(
    isInternal,
    currentCountry
  );

  // get account list
  const {
    data,
    isFetching: isTableLoading,
    refetch,
  } = useGetAccountList({
    ...searchParams,
    PageNum,
    PageSize: Number(DEFAULT_PERPAGE),
    isInternal,
    keepPreviousData: true,
    CountryName: currentCountry,
  });

  const pagination = get(data, "data");
  const dataSource = get(pagination, "data", []);
  const keyword = Form.useWatch("Keyword", form);

  const countryOptions = useMemo(() => {
    const countries = get(accountFilters, "data.countries", []);
    const defaultOption = { label: "All Countries", value: "" };
    if (!countries.length) {
      return [defaultOption];
    }

    return [
      defaultOption,
      ...countries.map((country) => ({ label: country, value: country })),
    ];
  }, [accountFilters]);

  const roleOptions = useMemo(() => {
    const roles = get(accountFilters, "data.roles", []);
    const defaultOption = { label: "All Roles", value: "" };

    if (!roles.length) {
      return [defaultOption];
    }

    return [
      defaultOption,
      ...roles.map((role) => ({ label: role, value: role })),
    ];
  }, [accountFilters]);

  const companyOptions = useMemo(() => {
    const defaultOption = { label: "All Depots", value: "" };

    const companies = get(accountFilters, "data.companies", []);
    if (!companies.length) {
      return [defaultOption];
    }

    return [
      defaultOption,
      ...companies.map((company) => ({
        label: company.name,
        value: company.code,
      })),
    ];
  }, [accountFilters]);

  const handOncloseAssigleRole = () => {
    setOpenModalAssignRole(false);
  };

  const tableColumns = useMemo(
    () =>
      isInternal
        ? defaultTableColumns
        : // add "depot" column to "defaultTableColumns" at the position "5" (before "email column")
          insertItemIntoArray(defaultTableColumns, 5, {
            title: <TableHeaderTitle title="Depot" />,
            dataIndex: "companyName",
            showSorterTooltip: false,
            sortIcon: CustomSortIcon,
            width: 100,
            ellipsis: true,
            sorter: (a, b) => {
              const companyNameA = a.companyName || "";
              const companyNameB = b.companyName || "";

              return companyNameA?.localeCompare(companyNameB);
            },
            render: (_value: any, record: Account, _index: number) => (
              <div className="flex flex-col">
                <CustomTooltip isShowCopy={true} copytext={record.companyName}>
                  <Typography.Text className="!block truncate font-roboto text-sm text-blue-midnight-express leading-4 font-normal">
                    {record.companyName}
                  </Typography.Text>
                </CustomTooltip>
              </div>
            ),
          }),
    [isInternal]
  );

  const handleClearKeyword = () => {
    form.setFieldValue("Keyword", "");
  };

  const handleApplyFilters = (values: Filters) => {
    // https://stackoverflow.com/questions/71077346/how-to-let-query-are-performed-at-the-component-onmount-and-triggered-by-user-ev/71093384#71093384
    // manually refetch and apply all filters (including search keyword)
    setPageNum(1); // reset page number
    setSearchParams(values);
  };

  const handleCancel = () => {
    if (setOpenModalAddNewAccount) {
      setOpenModalAddNewAccount(false);
    }
  };

  return (
    <>
      <Form form={form} onFinish={handleApplyFilters}>
        <div className="px-6 pt-4 flex items-center bg-white">
          <div className="flex items-center justify-end flex-wrap w-full">
            <CustomSelect
              name="RoleName"
              initialValue=""
              disabled={isLoading}
              loading={isLoading}
              placeholder="All Roles"
              options={roleOptions}
              customFormItemClassName="!mb-4 ml-3"
              listHeight={200}
              placeholderStyle="text-sm !text-blue-rock-blue font-roboto leading-4"
            />
            {!isInternal && (
              <CustomSelect
                name="CompanyName"
                initialValue=""
                disabled={isLoading}
                loading={isLoading}
                placeholder="All Depots"
                options={companyOptions}
                customFormItemClassName="!mb-4 ml-3"
                listHeight={200}
                placeholderStyle="text-sm !text-blue-rock-blue font-roboto leading-4"
              />
            )}
            <CustomSelect
              name="isActive"
              initialValue=""
              placeholder="Select status"
              customFormItemClassName="mb-4"
              options={statusOPtions}
              customClassName="!w-[7.9375rem] ml-3"
              placeholderStyle="text-sm !text-blue-rock-blue font-roboto leading-4"
            />
            <FormInput
              name="Keyword"
              customFormItemClassName={`${
                isInternal ? "" : "flex-1"
              } w-[33.125rem] sL:w-[14rem] ml-3 !mb-4`}
              placeholder="Search by CCGID, First Name, Last Name, Email, Created by, Updated by"
              prefix={
                <Image
                  preview={false}
                  src={SearchIcon}
                  alt="input-search-icon"
                />
              }
              suffix={
                keyword ? (
                  <img
                    className="cursor-pointer"
                    src={CrossIcon}
                    alt="CrossIcon"
                    onClick={handleClearKeyword}
                  />
                ) : null
              }
            />
            <Form.Item className="ml-3 !mb-4">
              <CustomButtom
                title="Apply"
                disabled={isLoading}
                customSize="small"
                customColor="primary"
                htmlType="submit"
              />
            </Form.Item>
          </div>
        </div>
      </Form>
      <div className="custom-table-email-management custom-table-accounts-management">
        <ConfigProvider renderEmpty={EmptyTable}>
          <Table
            pagination={false}
            loading={isTableLoading}
            columns={tableColumns}
            rowKey="ccgid"
            rowClassName="font-roboto text-sm font-normal"
            dataSource={dataSource as Account[]}
          />
        </ConfigProvider>
      </div>
      {dataSource.length >= 1 && (
        <div className="p-6 flex justify-between items-center border border-blue-hawkes-blue">
          <div>
            <Typography.Text className="font-roboto text-sm not-italic font-normal leading-4 text-blue-waikawa-grey">
              Results:&nbsp;
            </Typography.Text>
            <Typography.Text className="text-blue-midnight-express text-sm not-italic font-normal left-5">
              {pagination?.totalRecords}
            </Typography.Text>
          </div>
          <div className="custom-pagination-customer-management">
            <Pagination
              nextIcon={<img alt="pagination-next" src={AngleRight} />}
              prevIcon={<img alt="pagination-previous" src={AngleLeft} />}
              pageSize={pagination?.pageSize}
              current={PageNum}
              onChange={setPageNum}
              total={pagination?.totalRecords}
              showSizeChanger={false}
            />
          </div>
        </div>
      )}
      {!isOpenModalAddNewAccount && isOpenModalAssignRole && (
        <ModalAssignRole
          open={isOpenModalAssignRole}
          roleOptions={roleOptions}
          countryOptions={countryOptions}
          onclose={handOncloseAssigleRole}
          account={selectedAccount}
          refreshList={refetch}
          timeStamp={dayjs().toISOString()}
        />
      )}

      <ModalAccount
        open={isOpenModalAddNewAccount}
        onCancel={handleCancel}
        refreshList={refetch}
      />

      <ModalAccount
        isEdit={true}
        open={openModalEdit}
        account={selectedAccount}
        onCancel={() => setOpenModalEdit(false)}
        refreshList={refetch}
      />
    </>
  );
};
