import { LoginType, SK } from "constants/constants";
import { Navigate, useNavigate } from "react-router-dom";
import { encodeMessage, redirectSSOPageToLogin } from "libs/helper";
import { save, saveJSON } from "libs/storage";
import { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { AppLoading } from "components/AppLoading";
import { CustomButtom } from "components/button/CustomButton";
import { IAuthenticateRes } from "interfaces/authent-model";
import { LOCAL_STORAGE_KEYS } from "constants/endpoints";
import LogoSignIn from "assets/images/LogoSignIn.svg";
import { Typography } from "antd";
import { UnauthenticatedTemplate } from "@azure/msal-react";
import axios from "axios";
import { isEmpty } from "lodash";
import { isPingSSO } from "hooks/use-is-ping-sso";
import { loginRequest } from "libs/auth-config";
import { useGetQueryParam } from "hooks/use-get-query-param";
import { useNotification } from "hooks/use-notification";

export const Login = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();
  const [appLoading, setAppLoading] = useState(false);

  const getAccessToken = async (asscesToken: string | undefined) => {
    const apiBase: string = process.env.REACT_APP_DOMAIN as string;
    await axios
      .post(`${apiBase}/api/auth/login?asscesToken=${asscesToken}`, {})
      .then((res: { data: { data: IAuthenticateRes }; status: number }) => {
        if (res?.data) {
          save(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, res?.data?.data?.token);
          save(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, res?.data?.data?.refreshToken);
          saveJSON(LOCAL_STORAGE_KEYS.PING_INFO, res?.data?.data?.user);
          save(
            LOCAL_STORAGE_KEYS.LOG_TYPE,
            encodeMessage(
              `${LoginType.PingSSO}${res?.data?.data?.user.sub}`,
              SK
            )
          );
          setAppLoading(false);
          navigate("/edo");
        }
      })
      .catch(() => {
        setAppLoading(false);
        handleOpenFailNotification({
          message: "Something is wrong please try again",
        });
      });
  };

  const handleLogin = () => {
    save(LOCAL_STORAGE_KEYS.LOG_TYPE, encodeMessage(`${LoginType.Azure}`, SK));
    instance.loginRedirect(loginRequest);
  };

  const handleLoginSSO = () => {
    redirectSSOPageToLogin();
  };

  const hashQuery = useGetQueryParam();
  const { handleOpenFailNotification } = useNotification();

  useEffect(() => {
    if (isEmpty(hashQuery)) {
      setAppLoading(
        inProgress === "startup" ||
          inProgress === "login" ||
          inProgress === "handleRedirect"
      );
    }
  }, [inProgress, hashQuery]);

  useEffect(() => {
    if (!isEmpty(hashQuery) && hashQuery.access_token) {
      const checkAuth = async () => {
        await getAccessToken(hashQuery.access_token);
      };
      setAppLoading(true);
      checkAuth();
    }
  }, [hashQuery]);

  if (appLoading) {
    return <AppLoading />;
  }

  if (isAuthenticated || isPingSSO()) {
    return <Navigate to="/edo" />;
  }

  return (
    <UnauthenticatedTemplate>
      <div className="h-screen w-full overflow-hidden ">
        <div className="overflow-auto flex flex-col items-center justify-center h-full bg-[url('assets/images/LoginBackground.png')] overflow-x-hidden bg-cover bg-repeat bg-center">
          <div className="bg-white flex flex-col items-center pt-[52px] pb-[80px] px-[53px] w-[464px]">
            <img
              className="w-24 h-14 mb-4"
              src={LogoSignIn}
              alt="CMACGM-logo.svg"
            />
            <Typography.Text className="font-antonio text-blue-sapphire text-[32px] leading-[32px] font-bold mb-4 tracking-[-0.16px] ">
              Welcome back
            </Typography.Text>
            <Typography.Text className="text-center text-base font-normal font-roboto text-blue-waikawa-grey leading-[19px]">
              Login to continue using your account
            </Typography.Text>
            <div className="w-full my-8 border-red-base border border-solid border-x-0">
              <div className="mt-4 mb-6 borderOverRide flex flex-col justify-center items-center">
                <Typography.Text className="pb-3 text-base font-normal font-roboto text-blue-sapphire leading-6">
                  CMA CGM Group
                </Typography.Text>
                <CustomButtom
                  title="CMA USER LOGIN"
                  customSize="small"
                  customTextClassName="!text-white"
                  customClassName="!w-[85%] !bg-blue-sapphire"
                  customColor="secondary"
                  onClick={handleLogin}
                />
              </div>
              <div className="mb-6 borderOverRide flex flex-col justify-center items-center">
                <Typography.Text className="pb-3 text-base font-normal font-roboto text-blue-sapphire leading-6">
                  Partners
                </Typography.Text>
                <CustomButtom
                  title="EXTERNAL USER LOGIN"
                  customSize="small"
                  customTextClassName="!text-white"
                  customClassName="!w-[85%] !bg-blue-sapphire"
                  customColor="secondary"
                  onClick={handleLoginSSO}
                />
              </div>
            </div>
            <Typography.Text className="text-left text-xs font-extralight italic font-roboto text-blue-midnight-express leading-[14px]">
              Your User Name and email address will be collected for login
              purposes and to record all value changes made by you in this
              application. Only authorized users to the application would have
              access to your personal data. Such data would not be transferred
              outside of CMA CGM. You can opt out of the use of your personal
              data by contacting the Application Team via EUP, but please be
              aware that such opt-out will cause operational difficulties.
            </Typography.Text>
          </div>
        </div>
      </div>
    </UnauthenticatedTemplate>
  );
};
