import { IFieldAccess } from "./interfaces";

export const featureAccess: IFieldAccess[] = [
  {
    groupName: "Functional Access Control",
    groupKey: "functionalAccessControl",
    data: [
      {
        fieldName: "Export Excel",
        fieldKey: "exportExcel",
        importCS: true,
        logistic: true,
        counter: true,
        depot: false,
      },
      {
        fieldName: "Release eDO",
        fieldKey: "releaseEDO",
        importCS: false,
        logistic: false,
        counter: true,
        depot: false,
      },
      {
        fieldName: "Unrelease eDO",
        fieldKey: "unreleaseEDO",
        importCS: false,
        logistic: false,
        counter: true,
        depot: false,
      },
      {
        fieldName: "Shortship eDO",
        fieldKey: "shortshipEDO",
        importCS: true,
        logistic: false,
        counter: true,
        depot: false,
      },
      {
        fieldName: "Delete eDO",
        fieldKey: "deleteEDO",
        importCS: true,
        logistic: false,
        counter: true,
        depot: false,
      },
      {
        fieldName: "Restore eDO",
        fieldKey: "restoreEDO",
        importCS: true,
        logistic: false,
        counter: true,
        depot: false,
      },
      {
        fieldName: "View eDO PDF",
        fieldKey: "viewEDOPDF",
        importCS: true,
        logistic: true,
        counter: true,
        depot: true,
      },
      {
        fieldName: "View History",
        fieldKey: "viewHistory",
        importCS: true,
        logistic: true,
        counter: true,
        depot: false,
      },
    ],
  },
  {
    groupName: "Manage Emails",
    groupKey: "manageEmails",
    data: [
      {
        fieldName: "Resend Email",
        fieldKey: "resendEmail",
        importCS: false,
        logistic: false,
        counter: true,
        depot: false,
      },
    ],
  },
  {
    groupName: "Manage Accounts (Internal Users, Depot and Customers)",
    groupKey: "manageAccountsInternalUsersDepotAndCustomers",
    data: [
      {
        fieldName: "Edit Internal Users",
        fieldKey: "editInternalUsers",
        importCS: false,
        logistic: false,
        counter: false,
        depot: false,
      },
      {
        fieldName: "Edit Depot",
        fieldKey: "editDepot",
        importCS: false,
        logistic: true,
        counter: false,
        depot: false,
      },
      {
        fieldName: "Edit Customer",
        fieldKey: "editCustomer",
        importCS: false,
        logistic: false,
        counter: false,
        depot: false,
      },
    ],
  },
];

export const fieldAccess: IFieldAccess[] = [
  {
    groupName: "Voyage Information",
    groupKey: "voyageInformation",
    data: [
      {
        fieldName: "Voyage No.",
        fieldKey: "voyageNo",
        importCS: true,
        logistic: false,
        counter: false,
        depot: false,
      },
      {
        fieldName: "Arrival Date",
        fieldKey: "arrivalDate",
        importCS: true,
        logistic: false,
        counter: false,
        depot: false,
      },
      {
        fieldName: "Voyage Name",
        fieldKey: "voyageName",
        importCS: false,
        logistic: false,
        counter: false,
        depot: false,
      },
      {
        fieldName: "Vessel No.",
        fieldKey: "vesselNo",
        importCS: false,
        logistic: false,
        counter: false,
        depot: false,
      },
      {
        fieldName: "Carrier",
        fieldKey: "carrier",
        importCS: false,
        logistic: false,
        counter: false,
        depot: false,
      },
    ],
  },
  {
    groupName: "BL Information",
    groupKey: "blInformation",
    data: [
      {
        fieldName: "BL No.",
        fieldKey: "blNo",
        importCS: true,
        logistic: false,
        counter: true,
        depot: false,
      },
      {
        fieldName: "Consignee",
        fieldKey: "consignee",
        importCS: true,
        logistic: false,
        counter: true,
        depot: false,
      },
      {
        fieldName: "Receiving Party",
        fieldKey: "receivingParty",
        importCS: true,
        logistic: false,
        counter: true,
        depot: false,
      },
      {
        fieldName: "POD",
        fieldKey: "pod",
        importCS: true,
        logistic: false,
        counter: false,
        depot: false,
      },
      {
        fieldName: "FPOD",
        fieldKey: "fpod",
        importCS: true,
        logistic: false,
        counter: false,
        depot: false,
      },
    ],
  },
  {
    groupName: "Container Information",
    groupKey: "containerInformation",
    data: [
      {
        fieldName: "Total Container",
        fieldKey: "totalContainer",
        importCS: false,
        logistic: false,
        counter: false,
        depot: false,
      },
      {
        fieldName: "Container No.",
        fieldKey: "containerNo",
        importCS: false,
        logistic: false,
        counter: false,
        depot: false,
      },
      {
        fieldName: "Size",
        fieldKey: "size",
        importCS: false,
        logistic: false,
        counter: false,
        depot: false,
      },
      {
        fieldName: "Seal Number",
        fieldKey: "sealNumber",
        importCS: false,
        logistic: false,
        counter: false,
        depot: false,
      },
    ],
  },
  {
    groupName: "Delivery Information",
    groupKey: "deliveryInformation",
    data: [
      {
        fieldName: "Pickup Date",
        fieldKey: "pickupDate",
        importCS: true,
        logistic: false,
        counter: true,
        depot: false,
      },
      {
        fieldName: "Return Date",
        fieldKey: "returnDate",
        importCS: true,
        logistic: false,
        counter: true,
        depot: false,
      },
      {
        fieldName: "Update Reason",
        fieldKey: "updateReason",
        importCS: false,
        logistic: true,
        counter: true,
        depot: false,
      },
      {
        fieldName: "Empty Return Depot",
        fieldKey: "emptyReturnDepot",
        importCS: false,
        logistic: true,
        counter: false,
        depot: false,
      },
      {
        fieldName: "Security Code",
        fieldKey: "securityCode",
        importCS: false,
        logistic: false,
        counter: false,
        depot: false,
      },
      {
        fieldName: "Release No.",
        fieldKey: "releaseNo",
        importCS: false,
        logistic: false,
        counter: false,
        depot: false,
      },
    ],
  },
  {
    groupName: "Mass Update BL",
    groupKey: "massUpdateBL",
    data: [
      {
        fieldName: "Pickup Date",
        fieldKey: "massPickupDate",
        importCS: false,
        logistic: false,
        counter: true,
        depot: false,
      },
      {
        fieldName: "Return Date",
        fieldKey: "massReturnDate",
        importCS: false,
        logistic: false,
        counter: true,
        depot: false,
      },
      {
        fieldName: "Receiving Party",
        fieldKey: "massReceivingParty",
        importCS: false,
        logistic: false,
        counter: true,
        depot: false,
      },
      {
        fieldName: "Empty Return Depot",
        fieldKey: "massEmptyReturnDepot",
        importCS: false,
        logistic: true,
        counter: false,
        depot: false,
      },
      {
        fieldName: "Update Reason",
        fieldKey: "massUpdateReason",
        importCS: false,
        logistic: true,
        counter: true,
        depot: false,
      },
    ],
  },
];
