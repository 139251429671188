import { ConfigProvider, Table } from "antd";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
  TableRowSelection,
} from "antd/es/table/interface";
import { IChildrenTreeData, TreeDataType } from "../interfaces";
import { cloneDeep, difference, find, pullAt, union } from "lodash";
import { useEffect, useState } from "react";

import { DEFAULT_PERPAGE_EDOS } from "constants/constants";
import { EmptyTable } from "pages/accounts-management/components/EmptyTable";
import MinusExpanIcon from "assets/icons/MinusExpanIcon.svg";
import PlusExpanIcon from "assets/icons/PlusExpanIcon.svg";
import { SubTable } from "./SubTable";
import { UserRole } from "interfaces/common-model";
import { filterCanEditedoByRole } from "../helpers";
import { removeDuplicatesAndAddArray } from "libs/helper";
import { useMainTableColumn } from "../hooks/use-main-table-column";
import { useUserRole } from "./SelectRole";

interface ITableRender {
  callBackOnChangeTable: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TreeDataType> | SorterResult<TreeDataType>[]
  ) => void;
  childRowSelection: IChildrenTreeData[];
  setParentRowSelection: (callBackValue: any) => void;
  setChildRowSelection: (callBackValue: any) => void;
  parentRowSelection: string[];
  edoListData: TreeDataType[] | [];
  isTableLoading: boolean;
  setIsCheckedAll: (callBackValue: boolean) => void;
  setUncheckedItemAfterCheckedAll: (callBackValue: any) => void;
  unCheckedItemAfterCheckedAll: string[];
  isCheckedAll: boolean;
}

export const TableRender = ({
  callBackOnChangeTable,
  childRowSelection,
  setChildRowSelection,
  parentRowSelection,
  setParentRowSelection,
  edoListData,
  isTableLoading,
  setIsCheckedAll,
  setUncheckedItemAfterCheckedAll,
  isCheckedAll,
}: ITableRender) => {
  const mainColumns = useMainTableColumn();
  const role = useUserRole();
  const expandedRowRender = (record: TreeDataType) => {
    return (
      <SubTable
        record={record}
        childRowSelection={childRowSelection}
        setChildRowSelection={setChildRowSelection}
        setParentRowSelection={setParentRowSelection}
        parentRowSelection={parentRowSelection}
        setUncheckedItemAfterCheckedAll={setUncheckedItemAfterCheckedAll}
      />
    );
  };

  //indeterminate is checkbox status when all of it children yet to be checked
  const handleSetIndeterminate = (record: TreeDataType) => {
    const indeterminateArray: string[] = [];
    const filteredData = childRowSelection;
    filteredData.forEach((element) => {
      if (element.children?.length === element.totalChild?.length) {
        //if all children was check
        const findItemIndex = indeterminateArray.findIndex(
          (item) => item === element.parent
        );
        if (findItemIndex !== -1) {
          // remove parent out of array indeterminate
          indeterminateArray.splice(findItemIndex, 1);
        }
      } else {
        indeterminateArray.push(`${element.parent}`); //if not just push on indeterminate array
      }
    });
    return indeterminateArray.includes(record.blId); //return array of parent in indeterminate status
  };

  const parentRowSelectionRender: TableRowSelection<TreeDataType> = {
    selectedRowKeys: parentRowSelection,
    getCheckboxProps: (record) => ({
      indeterminate: handleSetIndeterminate(record),
      disabled:
        role === UserRole.Depot
          ? true
          : Boolean(!find(record.deliveryOrders, { canEdit: true })), // disable if there are only one disabled EDO children of DL
    }),
    // TODO: optimize
    onChange: (selectedRowKeys, selectedRows) => {
      // unCheck all parent
      if (selectedRowKeys.length === Number(DEFAULT_PERPAGE_EDOS)) {
        const childhandlerClone = cloneDeep(childRowSelection);
        selectedRows.forEach((item: TreeDataType) => {
          // find duplicate parent key on child array
          const findItem = childRowSelection.find(
            (finder) => finder.parent === item.blId
          );
          if (findItem) {
            //:  override it by shallow
            findItem!.children = filterCanEditedoByRole(item.deliveryOrders);
            setChildRowSelection(childhandlerClone);
            // end replace parent's children checkbox
          }
          //set all
          else {
            setChildRowSelection((prev: IChildrenTreeData[]) => [
              ...prev,
              {
                parent: item.blId,
                children: filterCanEditedoByRole(item.deliveryOrders),
                totalChild: [...item.deliveryOrders],
              },
            ]);
          }
        });
      }
    },
    onSelectAll: (selected, selectedRows) => {
      if (selected) {
        setParentRowSelection(selectedRows.map((item) => item?.blId));
        setIsCheckedAll(true);
        setUncheckedItemAfterCheckedAll([]);
      } else {
        setParentRowSelection([]);
        setChildRowSelection([]);
        setIsCheckedAll(false);
      }
    },
    onSelect: (
      record: TreeDataType,
      selected: boolean,
      selectedRows: TreeDataType[]
    ) => {
      let removedChilCheckbox: React.Key | undefined;

      removedChilCheckbox = record.blId;
      if (!selected) {
        //set unchecked child after select all
        if (isCheckedAll) {
          setUncheckedItemAfterCheckedAll((prev: string[]) => {
            return union(
              prev,
              record.deliveryOrders.map((item) => item.id)
            );
          });
        }
        //end set unchecked child after select all

        // getBase data, this data will same as parentRowSelection
        const filteredData = childRowSelection;
        const findItemByConvertedData = filteredData.find(
          (item) => item.parent === removedChilCheckbox
        );
        const childhandlerClone = cloneDeep(childRowSelection);
        const findItemIndex = childhandlerClone.findIndex(
          (item) => item.parent === removedChilCheckbox
        );

        // remove children out of array
        if (
          findItemByConvertedData?.totalChild?.length === //to make sure it check on parent
            findItemByConvertedData?.children?.length &&
          findItemIndex !== -1 //found deleted children
        ) {
          childhandlerClone.splice(findItemIndex, 1); //delete by shallow of "childhandlerClone"
        } else {
          //: if it not uncheck all, set checkall children instead
          childhandlerClone[findItemIndex]!.children =
            findItemByConvertedData!.totalChild.map((item) => item.id);
        }
        setChildRowSelection(childhandlerClone); //set by shallow
        const parentKeys = childhandlerClone.map((item) => item.parent); //get all parent key
        setParentRowSelection(parentKeys); //set by shallow
      } else {
        //set unchecked child after select all
        if (isCheckedAll) {
          setUncheckedItemAfterCheckedAll((prev: string[]) => {
            const cloneCurrentData = cloneDeep(prev);
            return [
              ...difference(
                cloneCurrentData,
                record.deliveryOrders.map((item) => item.id)
              ),
            ];
          });
        }
        //end set unchecked child after select all

        setParentRowSelection(
          removeDuplicatesAndAddArray(parentRowSelection, [record.blId])
        );
        // start replace parent's children checkbox
        // clone current child array
        const childhandlerClone = cloneDeep(childRowSelection);
        selectedRows.forEach((item: TreeDataType) => {
          // find duplicate parent key on child array
          const findItem = childRowSelection.find(
            (finder) => finder.parent === item?.blId
          );
          if (findItem) {
            //:  override it by shallow
            findItem!.children = filterCanEditedoByRole(item.deliveryOrders);
            setChildRowSelection(childhandlerClone);
            // end replace parent's children checkbox
          }
          //set all
          else {
            if (item?.blId)
              setChildRowSelection((prev: IChildrenTreeData[]) => [
                ...prev,
                {
                  parent: item.blId,
                  children: filterCanEditedoByRole(item.deliveryOrders),
                  totalChild: [...item.deliveryOrders],
                },
              ]);
          }
        });
      }
    },
  };
  const [expandRowKeys, setExpandRowKeys] = useState<string[]>([""]);

  useEffect(() => {
    const getAllKetRow = edoListData.map((item) => item.blId);
    setExpandRowKeys(getAllKetRow);
  }, [edoListData]);

  const onExpand = (expanded: boolean, record: TreeDataType) => {
    const isExpanedIndex = expandRowKeys.findIndex(
      (expandRowKey) => expandRowKey === record.blId
    );
    if (expanded) {
      setExpandRowKeys([...expandRowKeys, record.blId]);
    } else if (isExpanedIndex !== -1) {
      pullAt(expandRowKeys, isExpanedIndex);
    }
  };

  return (
    <ConfigProvider renderEmpty={EmptyTable}>
      {/* <div ref={topRef}> </div> */}
      <Table
        pagination={false}
        columns={mainColumns}
        onChange={callBackOnChangeTable}
        expandable={{
          onExpand: onExpand,
          expandedRowRender,
          expandedRowKeys: expandRowKeys,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <img
                src={MinusExpanIcon}
                alt="MinusExpanIcon"
                className="cursor-pointer"
                onClick={(e: any) => onExpand(record, e)}
              />
            ) : (
              <img
                src={PlusExpanIcon}
                alt="PlusExpanIcon"
                className="cursor-pointer"
                onClick={(e: any) => onExpand(record, e)}
              />
            ),
        }}
        rowSelection={{ ...parentRowSelectionRender }}
        dataSource={edoListData}
        loading={isTableLoading}
        rowKey="blId" //refer to table's column
      />
    </ConfigProvider>
  );
};
