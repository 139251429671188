import { useMutation, useQuery } from "react-query";

import { IBaseResponse } from "interfaces/common-model";
import { authenApi } from "./services";
import { fetchApi } from "libs/fetchAPI";

export const customerQueryKeys = {
  GET_USER_ROLE: "GET_USER_ROLE",
};

export interface IResponseRole {
  roles: { roleEnum: number; roleName: string; countryName: string }[];
  userName: string;
}

export const useGetUserRole = (isAuthenticated: boolean) =>
  useQuery({
    queryKey: [customerQueryKeys.GET_USER_ROLE],
    queryFn: () =>
      fetchApi<IBaseResponse<IResponseRole>>(authenApi.getUserRole()),
    enabled: isAuthenticated,
  });

export const useGetAccountInfo = () =>
  useMutation((baseToken: string) =>
    fetchApi<{ displayName: string; mail: string }>(
      authenApi.getAccountInfo(baseToken)
    )
  );

export const useCheckAccountUpdate = () =>
  useMutation(() =>
    fetchApi<
      IBaseResponse<{
        updatedFieldNames: string;
        message: string;
      }>
    >(authenApi.checkAccountUpdate())
  );
