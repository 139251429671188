import "./index.css";

import { App } from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import React from "react";
import ReactDOM from "react-dom/client";
import { msalConfig } from "libs/auth-config";
import reportWebVitals from "./reportWebVitals";
import { gaConfig } from "constants/configs";
import TagManager from "react-gtm-module";

// Google Analytics Manager initialization
TagManager.initialize({ gtmId: gaConfig.gtmCode });

export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App instance={msalInstance} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
