import { Upload as AntdUpload, Form, Typography, UploadProps } from "antd";
import { formatBytes, getFileTypeByName } from "libs/helper";
import { useEffect, useMemo, useState } from "react";

import FailUploadIcon from "assets/icons/FailUploadIcon.svg";
import { RcFile } from "antd/es/upload";
import RemoveFileIcon from "assets/icons/RemoveFileIcon.svg";
import { RuleObject } from "antd/es/form";
import { StoreValue } from "antd/es/form/interface";
import UploadFileIcon from "assets/icons/UploadFile.svg";
import useDidMountEffect from "hooks/use-did-mount-effect";

export interface ICustomerModal {
  callBackResetFile: () => void;
  triggerReset?: boolean;
  fileImages: { icon: string; type: string }[]; //you should self define which image in which case by using its index
  fileTypes: string[]; //must follow upper type
  overSizeValue: number; //refer MB
  customClassName?: string;
  loadingReadFile?: boolean;
  callBackHasError?: (value: boolean) => void;
}

export const Upload = ({
  callBackResetFile,
  triggerReset = false,
  fileImages,
  fileTypes,
  overSizeValue,
  customClassName = "",
  loadingReadFile = false,
  callBackHasError,
}: ICustomerModal) => {
  const [fileError, setFileError] = useState<{
    isEmpty: boolean;
    isWrongType: boolean;
    isOverSize: boolean;
  }>({
    isEmpty: false,
    isWrongType: false,
    isOverSize: false,
  });
  const [fileData, setFileData] = useState<{
    fileType?: string;
    size: number;
    file: RcFile | undefined;
  }>();
  const [hiddenDrag, setHiddenDrag] = useState(false);

  const resetStyleAndValidation = () => {
    setHiddenDrag(false);
    setFileData({
      size: 0,
      file: undefined,
    });
    setFileError({
      isEmpty: false,
      isWrongType: false,
      isOverSize: false,
    });
  };

  useEffect(() => {
    resetStyleAndValidation();
  }, [triggerReset]);

  const isFormError = useMemo(() => {
    return fileError.isOverSize || fileError.isWrongType || fileError.isEmpty;
  }, [fileError]);

  useDidMountEffect(() => {
    if (callBackHasError) {
      callBackHasError(isFormError);
    }
  }, [fileError, isFormError]);

  const props: UploadProps = {
    name: "File",
    onChange(info) {
      const { status } = info.file;
      if (status === "removed") {
        setFileData({ size: 0, file: undefined });
      }
      setHiddenDrag(status !== "removed");
    },
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <img src={RemoveFileIcon} alt="removeIcon" />,
      showPreviewIcon: true,
      previewIcon: <img src={RemoveFileIcon} alt="removeIcon" />,
    },
    beforeUpload() {
      return false;
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const normFile = (e: any) => {
    //always take any as type of antd EventArgs
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const customValidator = (_rule: RuleObject, value: StoreValue) => {
    if (!value) {
      setFileError({
        ...fileError,
        isEmpty: true,
      });
      return Promise.reject(" ");
    } else if (value.length > 0) {
      const isWrongType = fileTypes.includes(getFileTypeByName(value[0].name));
      const isOverSize = value[0].size > overSizeValue * 10 ** 7; //MB
      setFileError({
        ...fileError,
        isEmpty: false,
        isWrongType: !isWrongType,
        isOverSize: isOverSize,
      });
      if (isWrongType && !isOverSize) {
        setFileData({
          fileType: getFileTypeByName(value[0].name),
          size: value[0].size,
          file: value[0],
        });
        return Promise.resolve();
      } else {
        callBackResetFile();
        return Promise.reject(" ");
      }
    }
  };

  const renderImageFileByFileUpdate = useMemo(() => {
    return fileData?.fileType
      ? fileImages.find((image) => image.type === fileData.fileType)?.icon
      : "";
  }, [fileData, fileImages]);

  return (
    <div className={`mt-6 ${customClassName}`}>
      {fileData?.file && (
        <Typography.Text className="text-blue-sapphire font-roboto text-sm leading-4 font-normal">
          Upload File
        </Typography.Text>
      )}
      <div className="flex w-full">
        {fileData?.file && (
          <img
            className="mr-2"
            src={renderImageFileByFileUpdate as string}
            alt="FileIcon"
          />
        )}
        <div className="flex flex-col w-full [&_.ant-form-item-explain-error]:!hidden">
          <Form.Item
            name="File"
            className="mb-0"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                validator: customValidator,
                message: undefined,
              },
            ]}
          >
            <AntdUpload.Dragger
              disabled={loadingReadFile}
              className={` 
              [&_.ant-upload-drag]:border-dashed [&_.ant-upload-drag]:border-blue-solitude
            [&_.ant-upload-list-item-name]:!p-0 
            [&_.ant-upload-list-item-name]:!font-roboto 
            [&_.ant-upload-list-item-name]:font-sx 
            [&_.ant-upload-list-item-name]:font-normal 
            [&_.ant-upload-list-item-name]:!text-blue-midnight-express 
            [&_.ant-upload-list-item-actions>button]:!opacity-100 
            [&_.ant-upload-list-item]:!w-[90%]
            [&_.ant-upload-icon]:hidden 
            ${
              isFormError &&
              "[&_.ant-upload-list-item-container]:hidden [&_.ant-upload-drag]:border-solid [&_.ant-upload-drag]:border-red-torch"
            }
            ${
              hiddenDrag &&
              !fileError.isOverSize &&
              !fileError.isWrongType &&
              "[&_.ant-upload-drag]:hidden"
            }
          `}
              {...props}
            >
              <div
                className={`h-[178px] ${
                  loadingReadFile ? "cursor-not-allowed" : "cursor-pointer"
                } rounded-lg gap-2 flex flex-col items-center justify-center JOHN_V`}
              >
                <>
                  <img
                    src={isFormError ? FailUploadIcon : UploadFileIcon}
                    alt=""
                  />
                  {isFormError && (
                    <div className="text-red-torch font-roboto text-base font-normal leading-[18px]">
                      {fileError.isEmpty ? (
                        <span> This field must not be empty</span>
                      ) : (
                        <>
                          <span>Failed to upload file. </span>
                          {fileError.isOverSize && (
                            <span> The maximum size is 20MB.</span>
                          )}
                          {fileError.isWrongType && (
                            <span> Wrong file format</span>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  <span>
                    <span className="text-blue-royal-blue text-sm">
                      Click to upload
                    </span>
                    <span className="text-blue-waikawa-grey text-sm">
                      &nbsp;or drag and drop
                    </span>
                  </span>
                  {!isFormError && (
                    <span className="text-blue-waikawa-grey text-xs">
                      Supported Format .xls, .xlsx (Max &lt;20MB)
                    </span>
                  )}
                </>
              </div>
            </AntdUpload.Dragger>
          </Form.Item>
          {fileData?.file && (
            <Typography.Text className="font-roboto text-xs text-blue-waikawa-grey leading-[14px] font-normal">
              {formatBytes(fileData?.size)}
            </Typography.Text>
          )}
        </div>
      </div>
    </div>
  );
};
