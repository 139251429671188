import { Collapse, Image, Table } from "antd";

import ArrowDown from "assets/icons/ArrowDown.svg";
import ArrowUp from "assets/icons/ArrowUp.svg";
import { ColumnsType } from "antd/es/table/interface";
import { IFieldAccess } from "../interfaces";
import { SubTable } from "./SubTable";
import { useMemo } from "react";
import { userRoleStyles } from "../styles";

type TabItemProps = {
  data: IFieldAccess[];
  collapseActiveKeys: string[];
};

export const TabItem = ({ data, collapseActiveKeys }: TabItemProps) => {
  const tableColumns: ColumnsType<IFieldAccess> = useMemo(
    () => [
      {
        title: "",
        width: 162,
        onCell: (_, index) => ({
          colSpan: 5,
          rowSpan: 1,
        }),
        rowScope: "row",
        render: (_value: any, record: IFieldAccess, _index: number) => {
          return (
            <Collapse
              items={[
                {
                  label: record.groupName,
                  key: record.groupKey,
                  children: <SubTable data={record.data} />,
                },
              ]}
              bordered={false}
              defaultActiveKey={collapseActiveKeys}
              expandIconPosition="start"
              className={userRoleStyles.collapse}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <Image
                    preview={false}
                    alt="collapse-edo-detail"
                    src={ArrowUp}
                  />
                ) : (
                  <Image
                    preview={false}
                    alt="collapse-edo-detail"
                    src={ArrowDown}
                  />
                )
              }
            />
          );
        },
      },
      {
        title: "Import CS",
        width: "20%",
        onCell: () => ({ colSpan: 0 }),
      },
      {
        title: "Logistic",
        width: "20%",
        onCell: () => ({ colSpan: 0 }),
      },
      {
        title: "Counter",
        width: "20%",
        onCell: () => ({ colSpan: 0 }),
      },
      {
        title: "Depot",
        width: "20%",
        onCell: () => ({ colSpan: 0 }),
      },
    ],
    [collapseActiveKeys]
  );

  return (
    <Table
      rowKey="name"
      pagination={false}
      className={userRoleStyles.table.main}
      columns={tableColumns}
      dataSource={data}
    />
  );
};
