import { SidebarItemType } from "./types";
import { UserRole } from "interfaces/common-model";

export const SIDE_BAR_KEYS = {
  EDO: "edo",
  EMAIL: "email",
  CUSTOMERS: "customers",
  TERMINAL: "terminal-depot",
  DISCHARGE: "discharge",
  BKAV: "bkav",
  ACCOUNTS: "accounts",
  USER_ROLE: "user-role",
  MANAGEMENT: "management",
  DASHBOARD: "dashboard",
};

export const SIDE_BAR_MENU: SidebarItemType[] = [
  {
    //0
    key: SIDE_BAR_KEYS.EDO,
    label: "eDO",
    role: [
      UserRole.ITOfficer,
      UserRole.Admin,
      UserRole.ImportCS,
      UserRole.Logistic,
      UserRole.Counter,
      UserRole.Depot,
    ],
    icon: "edo",
  },
  {
    //1
    key: SIDE_BAR_KEYS.EMAIL,
    label: "Email",
    role: [
      UserRole.ITOfficer,
      UserRole.Admin,
      UserRole.ImportCS,
      UserRole.Counter,
    ],
    icon: "email",
  },
  {
    //2
    key: SIDE_BAR_KEYS.CUSTOMERS,
    label: "Customer List",
    role: [
      UserRole.ITOfficer,
      UserRole.Admin,
      UserRole.ImportCS,
      UserRole.Counter,
    ],
    icon: "customer",
  },
  {
    //3
    key: SIDE_BAR_KEYS.TERMINAL,
    label: "Terminal/Depot List",
    role: [UserRole.ITOfficer, UserRole.Admin, UserRole.Logistic],
    icon: "depot",
  },
  {
    //4
    key: SIDE_BAR_KEYS.DISCHARGE,
    label: "Pre-arrival List",
    role: [UserRole.ITOfficer, UserRole.Admin, UserRole.Logistic],
    icon: "discharge",
  },
  {
    //5
    key: SIDE_BAR_KEYS.BKAV,
    label: "BKAV Upload",
    role: [UserRole.ITOfficer],
    icon: "bkav",
  },
  {
    //6
    key: SIDE_BAR_KEYS.ACCOUNTS,
    label: "User Accounts",
    role: [
      UserRole.ITOfficer,
      UserRole.Admin,
      UserRole.ImportCS,
      UserRole.Logistic,
      UserRole.Counter,
    ],
    icon: "account",
  },
  {
    //7
    key: SIDE_BAR_KEYS.USER_ROLE,
    label: "User Role",
    role: [UserRole.ITOfficer, UserRole.Admin],
    icon: "role",
  },
];
