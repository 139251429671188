import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";

import { AccountManagement } from "pages/accounts-management";
import CustomerManagement from "pages/customer-management";
import { DepotManagement } from "pages/depot-management";
import DischargeListManagement from "pages/discharge-list-management";
import { EdoContextProvider } from "contexts/edo-context";
import EdoMain from "pages/eod-management";
import EmailManagement from "pages/email-management";
import { Login } from "pages/login";
import { NoMatchPage } from "pages/not-found";
import { NoPermission } from "pages/no-permission";
import { PrivateRoute } from "components/PrivateRoute";
import { SIDE_BAR_KEYS } from "layouts/constants";
import { UserRole } from "pages/user-role";
import BKAVManagement from "pages/bkav-management";
import { useCountry } from "pages/eod-management/components/SelectCountry";
import { get } from "lodash";
import { customerQueryKeys } from "layouts/queries";
import { queryClient } from "App";
import { Typography } from "antd";

const EdoWrapper = () => {
  const currentCountry = useCountry();

  const queryListRoleData: any = get(
    queryClient.getQueryData(customerQueryKeys.GET_USER_ROLE),
    "data.roles"
  );
  const hasRoleInCountry =
    currentCountry && queryListRoleData
      ? queryListRoleData.findIndex(
          (item: any) => item.countryName === currentCountry
        )
      : -2;

  if (hasRoleInCountry === -1) {
    return (
      <div className="h-full flex flex-col items-center justify-start pt-16">
        <Typography.Text className="mb-3 font-roboto font-normal text-xl text-blue-waikawa-grey leading-6">
          You don't have permission to view this data
        </Typography.Text>
      </div>
    );
  }

  return (
    <EdoContextProvider>
      <EdoMain />
    </EdoContextProvider>
  );
};
export const router = createBrowserRouter([
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <Login />,
      },
      {
        path: "no-permission",
        element: <NoPermission />,
      },
      {
        element: <PrivateRoute />,
        children: [
          { path: "", element: <Navigate to="edo" /> },
          {
            path: SIDE_BAR_KEYS.ACCOUNTS,
            element: <AccountManagement />,
          },
          {
            path: SIDE_BAR_KEYS.CUSTOMERS,
            element: <CustomerManagement />,
          },
          {
            path: SIDE_BAR_KEYS.EDO,
            element: <EdoWrapper />,
          },
          {
            path: SIDE_BAR_KEYS.EMAIL,
            element: <EmailManagement />,
          },
          {
            path: SIDE_BAR_KEYS.TERMINAL,
            element: <DepotManagement />,
          },
          {
            path: SIDE_BAR_KEYS.DISCHARGE,
            element: <DischargeListManagement />,
          },
          {
            path: SIDE_BAR_KEYS.BKAV,
            element: <BKAVManagement />,
          },
          {
            path: SIDE_BAR_KEYS.USER_ROLE,
            element: <UserRole />,
          },
          {
            path: "*",
            element: <NoMatchPage />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NoMatchPage />,
  },
]);
