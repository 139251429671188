import { ConfigProvider, Form, Image, Pagination } from "antd";
import {
  Discharge,
  DischargeFormValues,
} from "pages/discharge-list-management/interfaces";
import { difference, trim, union } from "lodash";
import {
  dischargeQueryKeys,
  useGetDischargeList,
  useImportDischargeList,
} from "pages/discharge-list-management/queries";
import { useCallback, useEffect, useRef, useState } from "react";

import AngleLeft from "assets/icons/AngleLeft.svg";
import AngleRight from "assets/icons/AngleRight.svg";
import { ButtonVariant } from "components/button/types";
import { CancelTokenSource } from "axios";
import CrossIcon from "assets/icons/CrossIcon.svg";
import { CustomButtom } from "components/button/CustomButton";
import {
  DEFAULT_PERPAGE_DISCHARGE,
  ERROR_MESSAGE_CODE,
} from "constants/constants";
import { DrawerBulkUpdateDischarge } from "./components/DrawerBulkUpdateDischarge";
import DrawerFilterDischargeList from "./components/DrawerFilterDischargeList";
import { EmptyTable } from "pages/accounts-management/components/EmptyTable";
import FailUploadIcon from "assets/icons/FailUploadIcon.svg";
import FilterIcon from "assets/icons/FilterIcon.svg";
import { FormInput } from "components/form/Input";
import ImportIcon from "assets/icons/ImportIcon.svg";
import { ModalConfirmation } from "components/ModalConfirmation";
import ModalProcessingUploadDischarge from "./components/ModalProcessingUploadDischarge";
import ModalUploadDischarge from "./components/ModalUploadDischarge";
import RedDotICon from "assets/icons/RedDotICon.svg";
import ResendIcon from "assets/icons/ResendIcon.svg";
import SearchIcon from "assets/icons/SearchIcon.svg";
import Table from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";
import { ValidationPreRead } from "./queries/types";
import dayjs from "dayjs";
import { queryClient } from "App";
import { useNotification } from "hooks/use-notification";
import { useTableColumns } from "pages/discharge-list-management/useTableColumns";
import { useCountry } from "pages/eod-management/components/SelectCountry";

export type FiltersDischarge = {
  fromCreatedDate?: string | null;
  toCreatedDate?: string | null;
  fromModifiedDate?: string | null;
  toModifiedDate?: string | null;
  listContainerSize?: string[];
  keyword?: string;
  isDepotNameEmpty?: boolean;
  countryName: string;
};

const INITIAL_FILTERS: FiltersDischarge = {
  fromCreatedDate: null,
  toCreatedDate: null,
  fromModifiedDate: null,
  toModifiedDate: null,
  listContainerSize: [],
  keyword: "",
  isDepotNameEmpty: false,
  countryName: "",
};

export default function DischargeListManagement() {
  const [PageNum, setPageNum] = useState(1);
  const [searchParams, setSearchParams] = useState(INITIAL_FILTERS);
  const [checkedRow, setCheckedRow] = useState<string[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);
  const [unCheckedItemAfterCheckedAll, setUncheckedItemAfterCheckedAll] =
    useState<string[]>([]);
  const [isFiltering, setIsFiltering] = useState<boolean>(true);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openBulkUpdateDischarge, setOpenBulkUpdateDischarge] =
    useState<boolean>(false);
  const cancelTokenSource = useRef<CancelTokenSource | undefined>();
  const [openUploadFileModal, setOpenUploadFileModal] = useState(false);
  const [openModalConfirmWhenCancel, setOpenModalConfirmWhenCancel] =
    useState<boolean>(false);
  const [showButtonX, setShowButtonX] = useState<boolean>(false);
  const { handleOpenSuccessNotification, handleOpenFailNotification } =
    useNotification();
  const [importDischargeError, setImportDischargeError] =
    useState<boolean>(false);
  const [duplicatedDischargeError, setDuplicatedDischargeError] =
    useState<boolean>(false);
  const [existedDischargeError, setExistedDischargeError] =
    useState<boolean>(false);
  const [openModalImport, setOpenModalImport] = useState<boolean>(false);
  const currentCountry = useCountry();

  useEffect(() => {
    setSearchParams((prve) => ({ ...prve, isDepotNameEmpty: true }));
  }, []);

  const {
    data: dischargeList,
    isFetching: isLoadingDischargeList,
    refetch,
  } = useGetDischargeList({
    ...searchParams,
    PageNum,
    PageSize: Number(DEFAULT_PERPAGE_DISCHARGE),
    countryName: currentCountry,
  });
  const [fileAfterRead, setFileAfterRead] = useState<ValidationPreRead>({
    fileName: "",
    fileSize: 0,
    numberOfItem: 0,
  });
  const columns = useTableColumns();

  const [form] = Form.useForm<DischargeFormValues>();
  const keyword = Form.useWatch("Keyword", form);
  const handleResetSearch = () => {
    form.setFieldValue("Keyword", "");
  };

  const handleOnChangePagination = (page: number) => {
    setPageNum(page);
  };

  const handleFormSearch = (values: DischargeFormValues) => {
    setPageNum(1);
    setSearchParams({
      ...searchParams,
      keyword: trim(values.Keyword),
    });
  };

  const rowSelection: TableRowSelection<Discharge> = {
    selectedRowKeys: checkedRow,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], _selectedRows: Discharge[]) => {
      setCheckedRow(selectedRowKeys as string[]);
    },
    onSelectAll: (selected) => {
      if (selected) {
        setIsCheckedAll(true);
        setUncheckedItemAfterCheckedAll([]);
      } else {
        setCheckedRow([]);
        setIsCheckedAll(false);
      }
    },
    onSelect: (record: Discharge, selected: boolean) => {
      if (isCheckedAll) {
        if (selected) {
          setUncheckedItemAfterCheckedAll((prev: string[]) => {
            return [...difference(prev, [record.id])];
          });
        } else {
          setUncheckedItemAfterCheckedAll((prev: string[]) => {
            return union(prev, [record.id]);
          });
        }
      }
    },
  };

  const handleOpenModalFilter = () => {
    setOpenFilter(true);
  };

  const handleApplyFilter = useCallback(
    (value: FiltersDischarge, isEmptyAll: boolean) => {
      setIsCheckedAll(false);
      setOpenFilter(false);
      setPageNum(1);
      setSearchParams((prev) => ({ ...prev, ...value }));
      setIsFiltering(!isEmptyAll);
      setCheckedRow([]);
      setUncheckedItemAfterCheckedAll([]);
    },
    [searchParams]
  );

  const handleCancelAllCheckBox = () => {
    setIsCheckedAll(false);
    setCheckedRow([]);
    setUncheckedItemAfterCheckedAll([]);
  };

  const {
    mutate: importDischarge,
    isLoading: isLoadingImportDischarge,
    isSuccess: isSuccessImportDischarge,
  } = useImportDischargeList();

  const filterRef = useRef<any>();

  const handleImportDischarge = useCallback((values: FormData | string[]) => {
    const payload = {
      file: values as FormData,
      cancelToken: cancelTokenSource,
    };
    setImportDischargeError(false);
    setDuplicatedDischargeError(false);
    setExistedDischargeError(false);
    importDischarge(payload, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [dischargeQueryKeys.GET_DISCHARGE_LIST_FILTER_DATA],
        });
        setIsFiltering(true);
        setSearchParams({ ...INITIAL_FILTERS, isDepotNameEmpty: true });
        form.resetFields();
        setCheckedRow([]);
        setIsCheckedAll(false);
        setUncheckedItemAfterCheckedAll([]);
        handleOpenSuccessNotification({
          message: "Your file has been uploaded successfully!",
        });
        setShowButtonX(true);
        filterRef?.current?.resetForward();
        refetch();
      },
      onError: (err: any) => {
        if (err?.response?.data?.Message === ERROR_MESSAGE_CODE.MSG_06) {
          // Duplicated in file import
          setDuplicatedDischargeError(true);
        }
        if (err?.response?.data?.Message === ERROR_MESSAGE_CODE.MSG_07) {
          // Existed in db
          setExistedDischargeError(true);
        } else setImportDischargeError(true);
      },
    });
    setOpenUploadFileModal(true);
    setOpenModalImport(false);
  }, []);

  const handleCallbackCheck = (value: ValidationPreRead) => {
    setFileAfterRead(value);
  };

  const handleOpenBulkUpdate = () => {
    setOpenBulkUpdateDischarge(true);
  };

  const refetchDischargeList = () => {
    setCheckedRow([]);
    setUncheckedItemAfterCheckedAll([]);
    refetch();
  };

  useEffect(() => {
    if (dischargeList?.data.data.length === 0) {
      if (PageNum !== 1) {
        setPageNum((prve) => prve - 1);
      } else {
        refetch();
      }
    }
  }, [dischargeList]);

  useEffect(() => {
    if (!isLoadingDischargeList && dischargeList && isCheckedAll) {
      setCheckedRow(
        difference(
          dischargeList.data.data.map((item) => item.id),
          unCheckedItemAfterCheckedAll
        )
      );
    }
  }, [
    isLoadingDischargeList,
    isCheckedAll,
    PageNum,
    unCheckedItemAfterCheckedAll,
  ]);

  return (
    <>
      <div className="flex flex-col">
        <div className="mb-6 flex justify-between items-center h-10">
          <h1 className="text-xl leading-6 font-bold font-antonio text-blue-sapphire tracking-[-0.1px] block">
            Discharge
          </h1>
          {checkedRow.length !== 0 ? (
            <div className="flex ">
              <CustomButtom
                title="Cancel"
                variant={ButtonVariant.outline}
                customSize={"medium"}
                customColor={"secondary"}
                customClassName="h-[40px]"
                onClick={handleCancelAllCheckBox}
              />
              <CustomButtom
                title={`Assign depot (${
                  isCheckedAll && dischargeList
                    ? dischargeList?.data.totalRecords -
                      unCheckedItemAfterCheckedAll.length
                    : checkedRow.length
                })`}
                customSize="large"
                customColor="secondary"
                customClassName="ml-2 h-[40px] flex items-center justify-center"
                customTextClassName="text-sm"
                icon={<img src={ResendIcon} alt="dowloadIcon" />}
                onClick={handleOpenBulkUpdate}
              />
            </div>
          ) : (
            <CustomButtom
              title="Import File"
              customSize="large"
              customColor="secondary"
              customClassName="ml-2 h-[40px] flex items-center justify-center"
              icon={<img src={ImportIcon} alt="dowloadIcon" />}
              onClick={() => setOpenModalImport(true)}
            />
          )}
        </div>
      </div>
      <div className="border border-solid border-blue-solitude">
        <div className="flex justify-end items-center bg-white px-[24px] border-solid py-[16px] border-blue-solitude">
          <Form
            initialValues={INITIAL_FILTERS}
            form={form}
            className="flex w-full justify-between"
            onFinish={handleFormSearch}
          >
            <div className="relative">
              {isFiltering && (
                <img
                  className="absolute right-[-5px] top-[-5px] z-10"
                  src={RedDotICon}
                  alt="redotIcon"
                />
              )}
              <CustomButtom
                title="Filter"
                customSize="small"
                customColor="primary"
                variant="outline"
                icon={<img src={FilterIcon} alt="add new account" />}
                customClassName="w-[100px] flex items-center justify-center"
                customTextClassName="text-blue-sapphire"
                onClick={handleOpenModalFilter}
              />
            </div>
            <div className="flex-wrap flex mx-[12px]">
              <FormInput
                name="Keyword"
                customFormItemClassName="ml-3 flex-1 !mb-4 w-[40.125rem] sL:w-[20rem] mr-3"
                placeholder="Search by BL No, Container No, Depot name, Updated by"
                prefix={
                  <Image
                    preview={false}
                    src={SearchIcon}
                    alt="input-search-icon"
                  />
                }
                suffix={
                  keyword ? (
                    <img
                      className="cursor-pointer"
                      src={CrossIcon}
                      alt="CrossIcon"
                      onClick={handleResetSearch}
                    />
                  ) : null
                }
              />
              <Form.Item className="mb-0">
                <CustomButtom
                  title="Apply"
                  customSize="small"
                  customColor="primary"
                  htmlType="submit"
                />
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className="custom-table-email-management custom-table-discharge-management">
          <ConfigProvider renderEmpty={EmptyTable}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={dischargeList?.data.data || []}
              pagination={false}
              loading={isLoadingDischargeList}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
            />
          </ConfigProvider>
          {dischargeList && dischargeList?.data.data.length >= 1 && (
            <div className="p-[24px] flex justify-between items-center border border-blue-hawkes-blue">
              <div>
                <span className="font-roboto text-[14px] not-italic font-normal leading-4 text-blue-waikawa-grey">
                  Results:&nbsp;
                </span>
                <span className="font-roboto text-blue-midnight-express text-[14px] not-italic font-normal left-5">
                  {dischargeList?.data.totalRecords}
                </span>
              </div>
              <div className="custom-pagination-customer-management">
                <Pagination
                  pageSize={dischargeList?.data?.pageSize}
                  current={PageNum}
                  onChange={handleOnChangePagination}
                  total={dischargeList?.data?.totalRecords}
                  nextIcon={<img alt="pagination-next" src={AngleRight} />}
                  prevIcon={<img alt="pagination-previous" src={AngleLeft} />}
                  showSizeChanger={false}
                />
              </div>
            </div>
          )}
        </div>
        <DrawerFilterDischargeList
          open={openFilter}
          setOpen={setOpenFilter}
          callBackApply={handleApplyFilter}
          ref={filterRef}
        />
        <ModalUploadDischarge
          modalTitle="Import File"
          subTitle="Submit information to upload file"
          callBackSuccess={handleImportDischarge}
          isOpen={openModalImport}
          setOpen={() => setOpenModalImport(false)}
          isSuccessConfirmation={isSuccessImportDischarge}
          isLoadingSubmit={isLoadingImportDischarge}
          callBackResponseData={handleCallbackCheck}
          country={currentCountry}
        />
        <ModalConfirmation
          customImage={
            <img
              className="w-[120px] h-[120px]"
              alt="logout-img"
              src={FailUploadIcon}
            />
          }
          open={openModalConfirmWhenCancel}
          okText={"Confirm"}
          onCancel={() => setOpenModalConfirmWhenCancel(false)}
          onOk={() => {
            setOpenModalConfirmWhenCancel(false);
            if (showButtonX) {
              handleOpenFailNotification({
                message: "Cannot cancel the request at the moment!",
              });
              return;
            }
            setOpenUploadFileModal(false);
            cancelTokenSource.current?.cancel("Process has been cancelled");
          }}
          cancelText={"Cancel"}
          title={"Cancel Importing Files"}
          description={"Your upload is not complete."}
          subDescription={"Do you want to cancel the upload?"}
          customClassName={"flex justify-center"}
        />
        {openUploadFileModal && fileAfterRead && (
          <ModalProcessingUploadDischarge
            showButtonX={showButtonX}
            isLoading={isLoadingImportDischarge}
            isSuccess={isSuccessImportDischarge}
            callBackClose={() => {
              setOpenUploadFileModal(false);
            }}
            callBackCancel={() => {
              setOpenModalConfirmWhenCancel(true);
            }}
            fileAfterRead={fileAfterRead}
            importDischargeError={importDischargeError}
            duplicatedDischargeError={duplicatedDischargeError}
            existedDischargeError={existedDischargeError}
          />
        )}
        <DrawerBulkUpdateDischarge
          isOpen={openBulkUpdateDischarge}
          filterDischargeData={searchParams}
          isSelectAll={isCheckedAll}
          listExceptDischargeId={unCheckedItemAfterCheckedAll}
          refetchDischargeList={refetchDischargeList}
          lisDischargeId={checkedRow}
          timestamp={dayjs().toISOString()}
          onClose={() => setOpenBulkUpdateDischarge(false)}
        />
      </div>
    </>
  );
}
