import { IFetchApi } from "libs/fetchAPI";
import { graphConfig } from "libs/auth-config";

export const authenApi = {
  getUserRole: (): IFetchApi => ({
    url: `accounts/list-roles2`,
    method: "get",
    isAuth: true,
  }),
  getAccountInfo: (baseToken: string): IFetchApi => ({
    url: ``,
    method: "get",
    isAuth: true,
    isUsingBaseUrl: {
      token: baseToken,
      baseUrl: graphConfig.graphMeEndpoint,
    },
  }),
  checkAccountUpdate: (): IFetchApi => ({
    url: `accounts/check-update`,
    method: "get",
    isAuth: true,
  }),
};
