import { Avatar, Dropdown, Menu, Skeleton, Tag, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  SelectUserRole,
  useUserRole,
} from "pages/eod-management/components/SelectRole";
import { clear, logout, save } from "libs/storage";
import { useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { DefaultOptionType } from "antd/es/select";
import { LOCAL_STORAGE_KEYS } from "constants/endpoints";
import LogoDashboard from "assets/images/LogoDashboard.svg";
import LogoutImage from "assets/images/Logout.svg";
import { LogoutOutlined } from "@ant-design/icons";
import { ModalConfirmation } from "components/ModalConfirmation";
import { Profile } from "../types";
import {
  SelectCountry,
  useCountry,
} from "pages/eod-management/components/SelectCountry";
import SkeletonAvatar from "antd/es/skeleton/Avatar";
import { UserRole } from "interfaces/common-model";
import { IResponseRole, customerQueryKeys } from "layouts/queries";
import { get } from "lodash";
import { getAcronym } from "libs/helper";
import { queryClient } from "App";
import { useGetCountryList } from "pages/accounts-management/queries";

type HeaderProps = {
  isFetchingProfile: boolean;
  profile: Profile;
};

export const Header = ({ profile, isFetchingProfile }: HeaderProps) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [isShowLogoutModal, setIsShowLogoutModal] = useState(false);
  const navigate = useNavigate();
  const country = useCountry();

  const { data: listCountry, isLoading: isLoadingGetCountry } =
    useGetCountryList({ isGetAll: false });
  useEffect(() => {
    if (!isLoadingGetCountry) {
      if (!listCountry) {
        navigate("/no-permission");
      } else {
        save(LOCAL_STORAGE_KEYS.COUNTRY, listCountry?.data[0]);
        window.dispatchEvent(new Event("storageCountry"));
      }
    }
  }, [isLoadingGetCountry]);
  const queryRoleData: IResponseRole | any = get(
    queryClient.getQueryData(customerQueryKeys.GET_USER_ROLE),
    "data"
  );
  const role = useUserRole(true);

  const handleLogout = () => {
    if (isAuthenticated) {
      //of Azure
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
      clear([
        LOCAL_STORAGE_KEYS.COUNTRY,
        LOCAL_STORAGE_KEYS.USER_ROLE,
        LOCAL_STORAGE_KEYS.USER_ROLE_TEMP_ADMIN,
      ]);
      // localStorage.clear();
    } else {
      navigate("/");
      logout();
    }
  };

  const acronym = getAcronym(queryRoleData?.userName);

  const handleCancelModal = () => {
    setIsShowLogoutModal(false);
  };
  const [openModal, setOpenModal] = useState<boolean | undefined>(true);
  useEffect(() => {
    if (!isLoadingGetCountry && !isFetchingProfile) {
      setTimeout(() => {
        setOpenModal(undefined);
      }, 100);
    }
  }, [isLoadingGetCountry, isFetchingProfile]);

  return (
    <div className="w-full px-[30px] py-3 bg-white flex items-center justify-between sticky top-0 left-0">
      <Link className="block" to="/">
        <img
          className="h-full"
          src={LogoDashboard}
          alt="cma-cmg-dashboard-logo"
        />
      </Link>
      <Dropdown
        open={openModal}
        dropdownRender={() => (
          <Menu className="!py-4 !px-6 custom-dropdown-menu">
            <Menu.Item
              key="profile"
              className="min-w-[200px] !rounded-none !mb-1 !flex !flex-col !hover:bg-white !px-0 !items-start justify-start"
            >
              <div className="flex">
                <Typography.Text className="block !font-antonio text-base font-bold leading-5 !text-blue-sapphire tracking-[0.04px]">
                  {queryRoleData?.userName}
                </Typography.Text>
                {[UserRole.ITOfficer, UserRole.Admin].includes(role) && (
                  <Tag className="ml-2 border-solid border border-blue-solitude">
                    <Typography.Text className="!font-Roboto text-xs font-normal not-italic leading-[14px] !text-blue-sapphire">
                      {UserRole.Admin === role ? "Admin" : "IT Officer"}
                    </Typography.Text>
                  </Tag>
                )}
              </div>
              <Typography.Text className="block text-blue-waikawa-grey text-sm leading-5 font-normal font-roboto mt-2">
                {profile?.mail}
              </Typography.Text>
            </Menu.Item>
            {!isLoadingGetCountry &&
              listCountry &&
              listCountry.data.length > 0 && (
                <>
                  <div className="flex justify-center items-center">
                    <Typography.Text className="block text-blue-rock-blue text-sm leading-5 font-normal font-roboto mr-2 ">
                      Country:
                    </Typography.Text>
                    <SelectCountry
                      listCountryOps={listCountry.data.map(
                        (country: string) => {
                          return {
                            label: country,
                            value: country,
                          } as DefaultOptionType;
                        }
                      )}
                    />
                  </div>
                  {queryRoleData?.roles &&
                    queryRoleData?.roles.length >= 1 &&
                    queryRoleData?.roles.findIndex(
                      (item: any) => item.countryName === country
                    ) !== -1 && (
                      <div className="mb-4 flex justify-center items-center">
                        <Typography.Text className="block text-blue-rock-blue text-sm leading-5 font-normal font-roboto mr-2 ">
                          View as:
                        </Typography.Text>
                        <SelectUserRole />
                      </div>
                    )}
                </>
              )}
            <Menu.Item
              key="logout"
              className="min-w-[20px] border-t border-solid border-t-blue-solitude !rounded-none !hover:bg-white !pt-4 !pb-2 !px-0"
              onClick={() => setIsShowLogoutModal(true)}
            >
              <LogoutOutlined className="text-red-base mr-2" />
              <Typography.Text className="font-roboto text-sm font-normal leading-5 !text-red-base">
                Log out
              </Typography.Text>
            </Menu.Item>
          </Menu>
        )}
      >
        <div className="flex items-center">
          {isFetchingProfile ? (
            <>
              <SkeletonAvatar className="mr-2" size={44} active />
              <Skeleton.Input size="small" active />
            </>
          ) : (
            <>
              {acronym && (
                <Avatar
                  className="bg-blue-sapphire mr-2 flex items-center justify-center cursor-pointer"
                  size={44}
                >
                  <Typography.Text className="block text-sm text-white font-roboto leading-4 font-normal">
                    {acronym}
                  </Typography.Text>
                </Avatar>
              )}
              <Typography.Text className="cursor-pointer text-base leading-6 text-blue-sapphire font-normal font-roboto min-w-[120px]">
                {queryRoleData?.userName}
              </Typography.Text>
            </>
          )}
        </div>
      </Dropdown>
      <ModalConfirmation
        open={isShowLogoutModal}
        okText="Confirm"
        customImage={<img alt="logout-img" src={LogoutImage} />}
        onCancel={handleCancelModal}
        onOk={handleLogout}
        cancelText={"Cancel"}
        title="Do you want to log out?"
      />
    </div>
  );
};
