import { Collapse, Typography } from "antd";
import { useEffect, useRef, useState } from "react";

import ArrowDownIcon from "assets/icons/ArrowDownIcon.svg";
import ArrowUpIcon from "assets/icons/ArrowUpIcon.svg";
import DangerIcon from "assets/icons/DangerIcon.svg";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import SuccessIcon from "assets/icons/SuccessIcon.svg";
import XCloseIcon from "assets/icons/XCloseIcon.svg";
import { findIndexByStatus } from "libs/helper";
import { queryClient } from "App";
import { useIsMutating } from "react-query";

const ModalUpdateAllEdo = () => {
  const isMutating = useIsMutating();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);
  const [showButtonX, setShowButtonX] = useState<boolean>(false);
  const currentRunningQueryIndex = useRef(-1);
  const checkHasRequest = useRef(false);
  const mutationCache = queryClient.getMutationCache();
  const mutations = mutationCache.getAll();

  useEffect(() => {
    const interval = setInterval(() => {
      if (findIndexByStatus("loading", mutations) !== -1) {
        currentRunningQueryIndex.current = findIndexByStatus(
          "loading",
          mutations
        );
        checkHasRequest.current = true;
        setLoading(true);
        setError(false);
        setShowButtonX(false);
      }
      if (
        currentRunningQueryIndex.current !== -1 &&
        mutations[currentRunningQueryIndex.current].state.status === "success"
      ) {
        setLoading(false);
        setError(false);
        setShowButtonX(true);
        clearInterval(interval);
      }

      if (
        currentRunningQueryIndex.current !== -1 &&
        mutations[currentRunningQueryIndex.current].state.status === "error"
      ) {
        setLoading(false);
        setError(true);
        setShowButtonX(true);
        clearInterval(interval);
      }
    }, 500); //interval 5ms check query on loading or not

    return () => clearInterval(interval);
  }, [isMutating]);

  const callBackClose = () => {
    setShowButtonX(false);
    setError(false);
    setLoading(false);
    checkHasRequest.current = false;
  };

  return checkHasRequest.current ? (
    <div className="z-[9999] absolute bottom-0 w-[307px]">
      <Collapse
        defaultActiveKey={["1"]}
        className="bg-white rounded-none rounded-t-lg shadow-popUpShadow [&_.ant-collapse-content]:border-t-0 [&_.ant-collapse-content-box]:!p-0"
        items={[
          {
            key: "1",
            label: (
              <div>
                <Typography.Text className="font-antonio font-bold text-base text-blue-sapphire leading-5 tracking-[-0.04px]">
                  Bulk Update All
                </Typography.Text>
              </div>
            ),
            children: (
              <div className="flex flex-col">
                <div className="flex p-4 justify-between items-center">
                  {isLoading ? (
                    <>
                      <Typography.Text className="font-roboto font-normal text-sm text-blue-waikawa-grey leading-4">
                        Bulk Updating Edos...
                      </Typography.Text>
                      <Loading3QuartersOutlined
                        className="text-blue-sapphire h-4"
                        spin
                      />
                    </>
                  ) : !isError ? (
                    <>
                      <Typography.Text className="font-roboto font-normal text-sm text-blue-waikawa-grey leading-4">
                        Update successfully
                      </Typography.Text>
                      <img src={SuccessIcon} alt="successIcon" />
                    </>
                  ) : (
                    <>
                      <Typography.Text className="font-roboto font-normal text-sm text-blue-waikawa-grey leading-4">
                        Update failed, please try again.
                      </Typography.Text>
                      <div>
                        <Typography.Text className="mr-2 font-roboto font-normal text-xs text-blue-rock-blue leading-[14px]">
                          Failed
                        </Typography.Text>
                        <img src={DangerIcon} alt="" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            ),
          },
        ]}
        expandIconPosition={"end"}
        expandIcon={({ isActive }) =>
          isActive ? (
            <div>
              {showButtonX ? (
                <img
                  onClick={callBackClose}
                  className="ml-[24px]"
                  src={XCloseIcon}
                  alt="closeIcon"
                />
              ) : (
                <img src={ArrowDownIcon} alt="open" />
              )}
            </div>
          ) : (
            <div>
              {showButtonX ? (
                <img
                  onClick={callBackClose}
                  className="ml-[24px]"
                  src={XCloseIcon}
                  alt="closeIcon"
                />
              ) : (
                <img src={ArrowUpIcon} alt="open" />
              )}
              {isLoading && (
                <Loading3QuartersOutlined
                  className="ml-5 text-blue-sapphire h-4"
                  spin
                />
              )}
            </div>
          )
        }
      />
    </div>
  ) : (
    <></>
  );
};

export default ModalUpdateAllEdo;
